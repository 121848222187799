
import { defineComponent } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "kt-account",
  components: {},
  setup() {
    const store = useStore();
  }
});
